import React from "react";
import Button from "../components/common/Button";
import Layout from "../components/common/Layout";
import Meta from "../components/common/Meta";

const ThankYouPage = () => {
  const meta = {
    title: "お問い合わせありがとうございます｜（株）Digital Stacks",
    description:
      "Digital Stacksは、DevOps、デジタルマーケティング、アナリティクス、ビジネスオペレーションなど、世界トップティアのクラウドテクノロジー製品を日本のお客様に提供しています。",
    pathname: "/contact-jp-thanks/",
  };
  return (
    <Layout>
      <Meta meta={meta} />
      <div className="flex justify-center items-center px-4 flex-col mt-16 mb-[80px]">
        <h1 className="font-sub font-bold text-center text-4xl md:text-[40px] leading-[60px] mb-4 text-dsc-title">
          Thank you
        </h1>
        <p className="text-sm leading-4 tracking-[0.03] text-dsc-body text-center mb-10">
          お問い合わせを受け付けました。 後ほど担当者よりご連絡いたします。
        </p>
        <Button
          title="HOMEへ戻る"
          size="lg"
          path="/"
          className="lg:px-[45px]"
        />
      </div>
    </Layout>
  );
};

export default ThankYouPage;
